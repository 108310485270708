import { ArrowLeftOutlined, LinkOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import { FC } from 'react'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import { Button } from 'src/@legacy/@core/components/share/ANTD'
import RightOutlined from 'src/@legacy/@optimal-page/components/base/icons/RightOutlined'
import { isHTML } from 'src/@legacy/utilities/common'
import {
  LINK_URL_COMPANY_JOB_LIST,
  LINK_URL_EMPLOYER_CONTACT,
  LINK_URL_HR_GLOSSARY,
  LINK_URL_HR_GLOSSARY_DETAIL,
  LINK_URL_LOGIN,
  LINK_URL_ROOT
} from 'src/@legacy/utilities/LinkURL'
import { GlossaryType } from '../../hr-glossary/components/HRGlossaryWrapper'
import CopyToClipboard from './CopyToClipboard'
import { HRGlossaryDetailStyle } from './HRGlossaryDetailStyle'
import { useCurrentCompany } from 'src/@legacy/hooks/CurrentCompanyHooks'

const HRGlossaryDetail: FC<{ glossaryDetail: GlossaryType }> = ({ glossaryDetail }) => {
  const [currentCompany] = useCurrentCompany()

  return (
    <>
      <style jsx>{HRGlossaryDetailStyle}</style>
      <div className="detail-heading">
        <Breadcrumb
          className="detail-breadcrumb"
          separator={<RightOutlined style={{ color: '#6c7b8d', fontSize: 16, margin: '0 12px' }} />}>
          <Breadcrumb.Item href={LINK_URL_ROOT}>Home</Breadcrumb.Item>
          <Breadcrumb.Item href={LINK_URL_HR_GLOSSARY}>Glossary</Breadcrumb.Item>
          <Breadcrumb.Item>Ad Hoc</Breadcrumb.Item>
        </Breadcrumb>
        <div className="hr-wrapper">
          <div className="detail__title">{glossaryDetail.title_article}</div>
          <div className="detail__summary">{glossaryDetail.summary}</div>
        </div>
      </div>
      <div className="hr-wrapper">
        <div className="detail-content">
          {isHTML(glossaryDetail.content) ? (
            <div dangerouslySetInnerHTML={{ __html: glossaryDetail.content }} />
          ) : (
            <div>{glossaryDetail.content}</div>
          )}

          <div className="d-flex share-wrapper">
            <CopyToClipboard copyValue={LINK_URL_HR_GLOSSARY_DETAIL(glossaryDetail.slug)}>
              <Button shape="circle" size="large">
                <LinkOutlined />
              </Button>
            </CopyToClipboard>

            <FacebookShareButton url={LINK_URL_HR_GLOSSARY_DETAIL(glossaryDetail.slug)}>
              <Button shape="circle" size="large">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <g clipPath="url(#7ws6plrj9a)">
                    <path
                      d="M11.657 20h-3.94a.46.46 0 0 1-.24-.471v-8.89H5.473c-.361 0-.461-.1-.461-.451V7.18c0-.351.1-.451.45-.451h2.176V4.234a3.478 3.478 0 0 1 .44-2.105A4.28 4.28 0 0 1 11.578.045c1.002-.1 2.084 0 3.127 0 .32 0 .42.11.42.44v2.817c0 .34-.1.451-.45.451H12.89c-.602 0-1.003.31-1.003 1.002V6.61a1.275 1.275 0 0 0 0 .17h2.707c.42 0 .53.121.48.542-.12 1.002-.23 2.005-.35 3.007 0 .3-.14.4-.461.4h-2.396V19.57a.501.501 0 0 1-.21.431z"
                      fill="#1877F2"
                    />
                  </g>
                  <defs>
                    <clipPath id="7ws6plrj9a">
                      <path fill="#fff" transform="translate(.012)" d="M0 0h20.012v20H0z" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </FacebookShareButton>
            <LinkedinShareButton url={LINK_URL_HR_GLOSSARY_DETAIL(glossaryDetail.slug)}>
              <Button shape="circle" size="large">
                <svg width="18" height="18" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.271.107 3.881 0h-.637c-.065.018-.131.034-.197.05a3.122 3.122 0 0 0-.388.11 2.806 2.806 0 0 0-1.298.863A2.827 2.827 0 0 0 .72 2.447a.526.526 0 0 1-.053.125v.65c0 .038.017.077.034.116.01.024.021.047.028.07.123.688.49 1.308 1.03 1.746a2.878 2.878 0 0 0 1.919.64 2.889 2.889 0 0 0 1.811-.767c.497-.46.818-1.08.908-1.752a2.876 2.876 0 0 0-.461-1.992A2.852 2.852 0 0 0 4.27.107zm20.185 12.951c-.08-.616-.162-1.233-.312-1.801a4.405 4.405 0 0 0-.854-1.782 4.379 4.379 0 0 0-1.546-1.226 6.738 6.738 0 0 0-3.153-.596 5.152 5.152 0 0 0-2.629.622 5.183 5.183 0 0 0-1.958 1.87s-.044 0-.106.062V7.982h-4.73V24h4.942v-7.867c.006-.556.047-1.112.124-1.664a2.551 2.551 0 0 1 .706-1.705 2.528 2.528 0 0 1 1.668-.777c1.585-.205 2.656.4 2.93 2.029.095.55.145 1.106.151 1.664V24h4.978v-9.7l-.089-.373a31.9 31.9 0 0 1-.122-.869zM5.98 24H1.092L1.056 7.982H5.98V24z"
                    fill="#3272AE"
                  />
                </svg>
              </Button>
            </LinkedinShareButton>
          </div>
          <Button size="large" type="text" className="detail__button-back" href={LINK_URL_HR_GLOSSARY}>
            <ArrowLeftOutlined />
            Trở về HR Glossary
          </Button>
          {/* <SimilarGlossary /> */}
        </div>
      </div>

      <div className="glossary-l4j">
        <div className="glossary-l4j__title">Muốn đăng tin tuyển dụng?</div>
        <div className="glossary-l4j__summary">freeC sẽ giúp bạn kết nối với các ứng viên tiềm năng một cách nhanh chóng!</div>
        <div className="d-flex glossary-l4j__footer">
          <Button
            href={currentCompany?.company?.id ? LINK_URL_COMPANY_JOB_LIST(currentCompany?.company?.id) : LINK_URL_LOGIN}
            type="primary">
            Đăng tin tuyển dụng miễn phí
          </Button>
          <Button href={LINK_URL_EMPLOYER_CONTACT}>Liên hệ</Button>
        </div>
      </div>
    </>
  )
}

export default HRGlossaryDetail
