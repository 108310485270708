import { Layout } from 'src/@legacy/@core/components/share/ANTD/Layout'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import React from 'react'
import { DefaultLayoutCompanyStyle } from '../../style/styled/DefaultLayoutCompany.style'
import { FreeCNextPage } from '../../type/Common'
import useIsLogin from 'src/@legacy/@optimal-page/hooks/useIsLogin'
import NotificationModal from 'src/modules/notifications/components/NotificationModal'

const HeaderBeforeLogin = dynamic(() => import('../HeaderBeforeLogin'))
const HeaderAfterLogin = dynamic(() => import('../Header'))
const Footer = dynamic(() => import('../FooterAfterLogin'))

const withHeaderDynamic = (Page: FreeCNextPage, className?: string) => {
  const isLogin = useIsLogin()

  const HOCCom = (props: any) => {
    return (
      <>
        <Head>
          <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}></script>
        </Head>
        <Layout className={className || 'company-layout'}>
          {!className && (
            <style jsx global>
              {DefaultLayoutCompanyStyle}
            </style>
          )}

          {isLogin ? (
            <>
              {NotificationModal && <NotificationModal />}
              <HeaderAfterLogin />
            </>
          ) : (
            <HeaderBeforeLogin />
          )}
          <div className="container">
            <Page {...props} />
          </div>
          <Footer />
        </Layout>
      </>
    )
  }
  HOCCom.getInitialProps = Page.getInitialProps
  return HOCCom
}
export default withHeaderDynamic
