import React, { FC } from 'react'
import Image from 'next/image'
const EmptyData: FC<{ ImageEmptyComponent?; title?: string; message?: string; FooterComponent? }> = ({
  ImageEmptyComponent,
  title,
  message,
  FooterComponent
}) => {
  return (
    <div>
      <style jsx>{`
        .empty-wrapper {
          padding: 60px 0;
        }
        .empty-wrapper .title {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          margin: 24px auto 4px;
          color: #141921;
        }
        .empty-wrapper .title-sub {
          font-size: 14px;
          line-height: 22px;
          color: #4f535b;
        }
      `}</style>

      <div className="empty-wrapper text-center">
        <div className="image">
          {ImageEmptyComponent ? ImageEmptyComponent : <Image src={'/img/empty_file_2.webp'} alt="file-image" width={128} height={128} />}
        </div>
        <p className="title mb-0 ">{title}</p>
        <p className="title-sub">{message}</p>
        {FooterComponent && FooterComponent}
      </div>
    </div>
  )
}

export default EmptyData
