import { FC } from 'react'

export const ALPHABET = [
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]
const HRGlossaryCategory: FC = () => {
  const handleScrollTo = (id: string) => {
    const elmnt = document.getElementById(id)
    elmnt?.scrollIntoView({
      behavior: 'smooth'
    })
  }
  return (
    <>
      <style jsx>{`
        .glossary-cate {
          text-align: center;
        }
        .glossary-cate a {
          display: inline-block;
          color: #4f535b;
          font-size: 16px;
          line-height: 22px;
          margin: 0 16px;
          font-weight: bold;
        }
        .glossary-cate a:first-child {
          margin-left: 0;
        }
        .glossary-cate a:last-child {
          margin-right: 0;
        }
        .glossary-cate a:hover {
          color: #1890ff;
        }

        @media only screen and (max-width: 576px) {
          .glossary-cate {
            position: relative;
            z-index: 1;
            display: flex;
            width: calc(100vw - 32px);
            overflow: auto;
          }

          .glossary-cate::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            border-radius: 0;
            background-color: inherit;
          }

          .glossary-cate::-webkit-scrollbar {
            width: 0;
            background-color: inherit;
          }

          .glossary-cate::-webkit-scrollbar-thumb {
            border-radius: 0;
            -webkit-box-shadow: none;
            background-color: inherit;
          }
        }
      `}</style>
      <div className="glossary-cate">
        {ALPHABET.map((key) => (
          <a key={key} onClick={() => handleScrollTo(`glossary-${key}`)}>
            {key}
          </a>
        ))}
      </div>
    </>
  )
}

export default HRGlossaryCategory
