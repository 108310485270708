import queryString from 'query-string'
import { FC, useMemo } from 'react'
import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { nomarlizeQuery } from 'src/@legacy/utilities/common'
import { LINK_URL_HR_GLOSSARY_DETAIL } from 'src/@legacy/utilities/LinkURL'
import HRGlossaryCategory from './HRGlossaryCategory'
import HRGlossarySearch, { GlossarySearch } from './HRGlossarySearch'
import { HRGlossaryStyle } from './HRGlossaryStyle'
import { GlossaryLevelType } from '../../hr-glossary-detail/components/GlossaryLevel'
import EmptyData from 'src/@legacy/components/EmptyData'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { Tooltip } from 'src/@legacy/@core/components/share/ANTD'

export type GlossaryType = {
  content: string
  date_created: string
  date_updated: string
  id: number
  sort: string

  status: string
  title_article: string
  user_created: string
  user_updated: string
  char: string
  slug: string
  id_hr_article: number
  difficult_tag: GlossaryLevelType
  summary: string
  key_words: string
}

const groupBy = (arr: GlossaryType[], filter?: GlossarySearch): { [char: string]: GlossaryType[] } =>
  arr
    .sort((a, b) =>
      a.title_article.toLowerCase() < b.title_article.toLowerCase()
        ? -1
        : b.title_article.toLowerCase() > a.title_article.toLowerCase()
        ? 1
        : 0
    )
    .reduce((group, glossary) => {
      const { char } = glossary
      group[char] = group[char] ?? []
      let condition = true
      if (filter.category) condition = condition && filter.category === String(glossary.id_hr_article)
      if (filter.search) condition = condition && glossary.title_article.toLowerCase().includes(String(filter.search).trim().toLowerCase())

      if ((!filter.category && !filter.search) || condition) group[char].push(glossary)
      return group
    }, {})

const HRGlossaryWrapper: FC<{ glossaryData: GlossaryType[] }> = ({ glossaryData }) => {
  const router = useAppRouter()
  const query = nomarlizeQuery(router.query)
  const { translate } = useTranslation()
  const filter = useMemo(
    () => ({
      search: query.search,
      category: query.category
    }),
    [query]
  )
  const groupGlossaryData = useMemo<{ [char: string]: GlossaryType[] }>(() => (glossaryData ? groupBy(glossaryData, filter) : {}), [
    glossaryData,
    filter.category,
    filter.search
  ])

  const pushSearch = (value: GlossarySearch) => {
    const q = queryString.stringify(value, { arrayFormat: 'bracket', decode: false, encode: false }) || ''
    router.push(router.asPath.split('?')[0] + '?' + q)
  }

  return (
    <>
      <style jsx>{HRGlossaryStyle}</style>
      <div className="banner">
        <h1 className="banner__title">HR Glossary - Thuật ngữ ngành Nhân sự</h1>
        <div className="banner__summary">
          Tổng hợp A-Z thuật ngữ ngành nhân sự từ cơ bản đến nâng cao, hữu ích với cả HR dày dặn kinh nghiệm và cả những "newbie" mới bước
          chân vào nghề.
        </div>
      </div>
      <div className="wrapper-container">
        <div className="glossary-wrapper">
          <HRGlossarySearch initialValues={filter} onSubmit={pushSearch} />
          <HRGlossaryCategory />
          {Object.values(groupGlossaryData).find((glossaryList) => glossaryList.length > 0) ? (
            Object.keys(groupGlossaryData).map((char) =>
              groupGlossaryData[char].length > 0 ? (
                <div id={`glossary-${char}`} key={`glossary-${char}`} className="glossary-result">
                  <h2 className="glossary-result__title">{char}</h2>
                  <div className="grid-contain">
                    {groupGlossaryData[char].map((glossary) => (
                      <div key={`glossary-${char}-${glossary.id}`} className="glossary-result__item">
                        <Tooltip title={glossary.title_article}>
                          <a target="_blank" href={LINK_URL_HR_GLOSSARY_DETAIL(glossary.slug)} rel="noreferrer">
                            {glossary.title_article}
                          </a>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
            )
          ) : (
            <EmptyData title={translate('chat:no_result_found')} message={translate('empty:no_results_with_filter')} />
          )}
        </div>
      </div>
    </>
  )
}

export default HRGlossaryWrapper
