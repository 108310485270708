import Head from 'next/head'
import React, { FC } from 'react'
import env from 'src/@legacy/utilities/env'
import { LINK_URL_EMPLOYER_HR_GLOSSARY } from 'src/@legacy/utilities/LinkURL'

const BreadcrumbTags: FC = () => {
  const breadcrumObject = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: env.PUBLIC_EMPLOYER_APP_DOMAIN
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Glossary',
        item: `${env.PUBLIC_EMPLOYER_APP_DOMAIN}${LINK_URL_EMPLOYER_HR_GLOSSARY}`
      }
    ]
  }
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `${JSON.stringify(breadcrumObject)}` }} />
    </Head>
  )
}

export default BreadcrumbTags
