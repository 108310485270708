import { SearchOutlined } from '@ant-design/icons'
import Image from 'next/image'
import { FC, useEffect, useState } from 'react'
import { Form, Input, Select } from 'src/@legacy/@core/components/share/ANTD'
import { useClient } from 'src/@legacy/@core/hooks/useClient'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { API_URL_HR_GLOSSARY_TITLE } from 'src/@legacy/@core/utilities/APIConstant'
import { parsePramsToQueryString } from 'src/@legacy/utilities/common'
import BookIcon from '../assets/application_read.svg'

type HRTitleType = {
  date_created: string
  date_updated: string
  hr_article_content: number[]
  id: number
  sort: string
  status: string
  title_en: string
  title_vi: string
  user_created: string
  user_updated: string
}
export type GlossarySearch = {
  search?: string | string[]
  category?: number | string | string[]
}
const HRGlossarySearch: FC<{ initialValues: GlossarySearch; onSubmit: (value: GlossarySearch) => void }> = ({
  initialValues,
  onSubmit
}) => {
  const [hrTitleList, setHRTitleList] = useState<HRTitleType[]>()
  const { client } = useClient()
  const { currentLanguage } = useTranslation()
  const fetchGlossaryCates = () => {
    return client.get(API_URL_HR_GLOSSARY_TITLE, {}).then((rs) => setHRTitleList(rs.data.data))
  }

  useEffect(() => {
    fetchGlossaryCates()
  }, [])
  return (
    <Form initialValues={initialValues}>
      <style jsx global>{`
        .glossary-search {
          position: relative;
          z-index: 1;
          margin-top: -40px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 8px 0 rgba(168, 178, 186, 0.3);
          padding: 16px;

          margin-bottom: 40px;
        }
        .glossary-search__wrapper {
          display: flex;
          justify-content: space-between;
        }
        .glossary-search__input {
          width: calc(77% - 8px);
        }
        .glossary-search__input .ant-input-affix-wrapper {
          height: 48px;
        }
        .glossary-search__input .ant-input-prefix {
          margin-right: 8px;
        }
        .glossary-search__input input::placeholder,
        .glossary-search__title .ant-select-selection-placeholder {
          color: #7a7c82;
        }
        .glossary-search__title {
          width: calc(23% - 8px);
          position: relative;
        }
        .glossary-search__title .ant-select {
          width: 100%;
        }
        .glossary-search__title .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
        .glossary-search__title
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector
          .ant-select-selection-search-input {
          height: 48px;
        }
        .glossary-search__title .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
          line-height: 48px;
        }
        .glossary-search__title .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          padding-left: 34px;
        }
        .glossary-search__title .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
          line-height: 48px;
        }
        .glossary-search__title-icon {
          position: absolute;
          z-index: 1;
          top: calc(50% - 8px);
          left: 10px;
        }

        @media only screen and (max-width: 576px) {
          .glossary-search__wrapper {
            display: block;
          }
          .glossary-search {
            margin-top: -67px;
            margin-bottom: 24px;
          }
          .glossary-search__input {
            width: 100%;
            margin-bottom: 8px;
          }
          .glossary-search__title {
            width: 100%;
          }
        }
      `}</style>
      <div className="glossary-search">
        <div className="glossary-search__wrapper">
          <div className="glossary-search__input">
            <Form.Item name="search" className="mb-0">
              <Input
                allowClear
                placeholder="Tìm kiếm thuật ngữ"
                onKeyUp={(e) => {
                  if (e.keyCode === 13) onSubmit({ category: initialValues.category, search: e.currentTarget.value })
                }}
                prefix={<SearchOutlined style={{ fontSize: 16, color: '#6c7b8d' }} />}
                onChange={(e) => {
                  if (!e.currentTarget.value) onSubmit({ category: initialValues.category, search: '' })
                }}
              />
            </Form.Item>
          </div>
          <div className="glossary-search__title">
            <span className="glossary-search__title-icon">
              <Image src={BookIcon} />
            </span>
            <Form.Item name="category" className="mb-0">
              <Select
                allowClear
                onChange={(value) => {
                  onSubmit({ search: initialValues?.search, category: value ? String(value) : '' })
                }}
                options={(hrTitleList || []).map((hrTitle) => ({
                  label: currentLanguage === 'en' ? hrTitle.title_en : hrTitle.title_vi,
                  value: String(hrTitle.id)
                }))}
                placeholder="Chủ đề"
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default HRGlossarySearch
