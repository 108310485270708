import { THUMBNAIL_COMPANY_LP_HH } from '../../@core/utilities/Constant'
import React, { FC } from 'react'
import LogoTags from 'src/@legacy/@optimal-page/pages/employer/headhunt-service/schema/LogoTags'
import BreadcrumbTags from './components/BreadcrumbTags'
import HRGlossaryWrapper, { GlossaryType } from './components/HRGlossaryWrapper'
// import { LINK_URL_EMPLOYER_CONTACT } from 'src/@legacy/utilities/LinkURL'
import PublicPageMeta from 'src/@legacy/@core/components/SEOMeta/PublicPageMeta'
import { LINK_URL_EMPLOYER_HR_GLOSSARY } from 'src/@legacy/utilities/LinkURL'

const HRGlossaryPage: FC<{ glossaryData: GlossaryType[] }> = ({ glossaryData }) => {
  return (
    <>
      <PublicPageMeta
        title={`${glossaryData.length} thuật ngữ ngành Nhân Sự mà mọi HR cần biết| freeC Asia`}
        description={`Tổng hợp A-Z thuật ngữ ngành nhân sự từ cơ bản đến nâng cao, hữu ích với cả HR dày dặn kinh nghiệm và cả những "newbie" mới bước chân vào nghề.`}
        keywords=""
        image={`${process.env.PUBLIC_EMPLOYER_APP_DOMAIN}${THUMBNAIL_COMPANY_LP_HH}`}
        url={`${process.env.PUBLIC_EMPLOYER_APP_DOMAIN}${LINK_URL_EMPLOYER_HR_GLOSSARY}`}
      />
      <BreadcrumbTags />
      <LogoTags />
      <HRGlossaryWrapper glossaryData={glossaryData} />
    </>
  )
}
export default HRGlossaryPage
