import Head from 'next/head'
import { FC } from 'react'
import env from 'src/@legacy/utilities/env'

const LogoTags: FC = () => {
  const logoObject = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: env.PUBLIC_EMPLOYER_APP_DOMAIN,
    logo: `${env.PUBLIC_EMPLOYER_APP_DOMAIN}/img/freec-logo-blue.svg`
  }
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `${JSON.stringify(logoObject)}` }} />
    </Head>
  )
}

export default LogoTags
