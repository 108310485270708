import { THUMBNAIL_COMPANY_LP_HH } from '../../@core/utilities/Constant'
import React, { FC } from 'react'
import { GlossaryType } from '../hr-glossary/components/HRGlossaryWrapper'
import HRGlossaryDetail from './components/HRGlossaryDetail'
import PublicPageMeta from 'src/@legacy/@core/components/SEOMeta/PublicPageMeta'
import { LINK_URL_HR_GLOSSARY_DETAIL } from 'src/@legacy/utilities/LinkURL'
import { EliminateQueryCanonical } from 'src/@legacy/@optimal-page/wrapper/jobs/SEOMeta/CanonicalMeta'
import LogoTags from 'src/@legacy/@optimal-page/pages/employer/headhunt-service/schema/LogoTags'
import BreadcrumbTags from './components/BreadcrumbTags'

const HRGlossaryDetailPage: FC<{ glossaryDetail: GlossaryType }> = ({ glossaryDetail }) => {
  return (
    <>
      <EliminateQueryCanonical
        rootDomain={`${process.env.PUBLIC_EMPLOYER_APP_DOMAIN}${LINK_URL_HR_GLOSSARY_DETAIL(glossaryDetail.slug)}`}
      />
      <BreadcrumbTags title={glossaryDetail.title_article} />
      <LogoTags />
      <PublicPageMeta
        title={glossaryDetail.title_article}
        description={glossaryDetail.summary}
        keywords={glossaryDetail.key_words}
        image={`${process.env.PUBLIC_EMPLOYER_APP_DOMAIN}${THUMBNAIL_COMPANY_LP_HH}`}
        url={`${LINK_URL_HR_GLOSSARY_DETAIL(glossaryDetail.slug)}`}
      />

      <HRGlossaryDetail glossaryDetail={glossaryDetail} />
    </>
  )
}
export default HRGlossaryDetailPage
