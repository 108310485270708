import { FC } from 'react'
import { openNotificationWithIcon } from 'src/@legacy/@core/utilities/Notification'

const CopyToClipboard: FC<{ copyValue?: string }> = ({ copyValue, children }) => {
  const copy = () => {
    navigator.clipboard.writeText(copyValue)
    openNotificationWithIcon('success', 'Đường dẫn chia sẻ đã được copy', '')
  }
  return <div onClick={() => copy()}>{children}</div>
}

export default CopyToClipboard
